import React, { useEffect, useState } from "react";
import NavbarSearch from "../navbar-search";
import { AppLogo } from "../app-logo";
import "./nav-bar.m.css";
import { MenuIcon } from "../../basic/icons/menu-icon";
import { TextButton } from "../text-button";
import Drawer from "../drawer";
import { UserPanel } from "../user-panel";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import * as actionTypes from "../../store/actionTypes";

import LoginModal from "../../molecules/LoginModal";
import RegisterModal from "../../molecules/RegisterModal";
import AlertModal from "../../molecules/AlertModal";
import ForgotPasswordModal from "../../molecules/ForgotPasswordModal";
import { Link } from "@quintype/components";
import { createLogin, getSectionFromUrl, getSubscriptionForUser } from "../../helper/helper";

function NavBar(props) {
  const [showDrawer, isDrawerVisible] = React.useState(false);
  const [userSubscription, setUserSubscription] = React.useState([]);

  const showAlertModal = useSelector((state) => get(state, ["authReducer", "alertModal"], false));
  const showRegisterModal = useSelector((state) => get(state, ["authReducer", "registerModal"], false));
  const showLoginModal = useSelector((state) => get(state, ["authReducer", "loginModal"], false));
  const showResetModal = useSelector((state) => get(state, ["authReducer", "resetModal"], false));
  const enableLogin = useSelector((state) => get(state, ["authReducer", "enableLogin"], false));
  const member = useSelector((state) => get(state, ["member"], null));
  const currentPath = useSelector((state) => get(state, ["qt", "currentPath"], ""));
  const accessTypeKey = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "accesstype", "key"], "")
  );
  const accessTypeIntegrationId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "accesstype", "integrationId"], "")
  );
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  function openLoginModal() {
    dispatch({
      type: actionTypes.LOGIN_MODAL,
      payload: true,
    });
  }

  function closeModal() {
    dispatch({
      type: actionTypes.LOGIN_MODAL,
      payload: false,
    });
    dispatch({
      type: actionTypes.REGISTER_MODAL,
      payload: false,
    });
    dispatch({
      type: actionTypes.ALERT_MODAL,
      payload: false,
    });
    dispatch({
      type: actionTypes.RESET_MODAL,
      payload: false,
    });
  }

  function toggleDrawer() {
    if (!showDrawer) {
      document.body.classList.add("no-scroll");
      isDrawerVisible(true);
    } else {
      document.body.classList.remove("no-scroll");
      setTimeout(() => {
        isDrawerVisible(false);
      }, 500);
    }

    setTimeout(() => {
      document.getElementById("nav-drawer").classList.toggle("slide-in-drawer");
    }, 100);
  }

  const getCurrentUser = async () => {
    // Import current user function only when this function is called
    const { currentUser } = await import("@quintype/bridgekeeper-js");
    try {
      const currentUserResp = await currentUser();
      const user = get(currentUserResp, ["user"], null);
      dispatch({ type: actionTypes.MEMBER_UPDATED, member: user });
      dispatch({ type: actionTypes.ENABLE_LOGIN });
      dispatch({ type: actionTypes.ENABLE_GOOGLE_ONETAP });

      if (user) {
        posthog.identify(user.id, {
          email: user.email,
          name: user.name,
        });
      }

      getSubscriptionForUser(accessTypeKey, accessTypeIntegrationId)
        .then((res) => {
          console.log(res.status);
          setLoading(false);
          if (user) {
            if (res.subscriptions && res.subscriptions.length > 0) {
              setUserSubscription(res.subscriptions);
              posthog.capture("$set", {
                $set: { subscriber: true },
              });
            } else {
              posthog.capture("$set", {
                $set: { subscriber: false },
              });
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });

      if (currentUserResp.user) {
        const section = getSectionFromUrl(window.location.pathname);
        const data = {
          section,
        };
        createLogin(data);
      }
    } catch (err) {
      console.log("error--------", err);
      setLoading(false);
    }
  };

  const logoutHandler = async () => {
    // Import logout on click of the logout button
    const { logout } = await import("@quintype/bridgekeeper-js");
    logout().then(() => {
      dispatch({
        type: actionTypes.MEMBER_UPDATED,
        member: null,
      });

      dataLayer.push({
        loginStatus: "Logged-Out",
        event: "user-logout",
      });

      dataLayer.push({
        user_id: undefined,
        userType: "Unknown",
        loginType: undefined,
      });

      posthog.reset();

      window.location.reload();
    });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (member) {
      const url = window.location.origin + currentPath;
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  }, [member, currentPath]);

  useEffect(() => {
    const handlePageleave = () => {
      if (member) {
        posthog.capture("$pageleave");
      }
    };
    window.addEventListener("beforeunload", handlePageleave);

    return () => {
      window.removeEventListener("beforeunload", handlePageleave);
    };
  }, [member]);

  let activeSubscription =
    userSubscription.length > 0 && userSubscription.find((subscription) => subscription.active === true);

  return (
    <React.Fragment>
      {showDrawer && <Drawer click={toggleDrawer} logout={logoutHandler} />}
      <div styleName="header-root">
        <AppLogo homeClick={props.homeClick} />
        <div styleName="menu-container">
          {!activeSubscription && !loading && (
            <Link href="/subscription" styleName="shimmer" id="subscribe-click">
              Subscribe
            </Link>
          )}
          {enableLogin && (
            <React.Fragment>
              {member && member["verification-status"] ? (
                <React.Fragment>
                  <UserPanel user={member || {}} logout={logoutHandler} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {showLoginModal && (
                    <LoginModal showModal={showLoginModal} closeModal={closeModal} path={props.currentPath} />
                  )}
                  {showRegisterModal && <RegisterModal showModal={showRegisterModal} closeModal={closeModal} />}
                  {showAlertModal && <AlertModal showModal={showAlertModal} closeModal={closeModal} />}
                  {showResetModal && <ForgotPasswordModal showModal={showResetModal} closeModal={closeModal} />}
                  <TextButton id="header-login-button" onClick={openLoginModal} label="Login" />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <React.Fragment>
            <NavbarSearch {...props} />
            <div id="header-drawer-icon" style={{ cursor: "pointer" }} onClick={toggleDrawer}>
              <MenuIcon />
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NavBar;
