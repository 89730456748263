import React, { useState, useEffect } from "react";
import "./login.m.css";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { RightArrowIcon } from "../../basic/icons/right-arrow-icon";
import { CloseIcon } from "../../basic/icons/close-icon";
import SocialLogin from "../SocialLogin";
import assetify from "@quintype/framework/assetify";
import { login, currentUser, sendVerificationLink } from "@quintype/bridgekeeper-js";
import afkLogo from "../../../../assets/images/afk_logo_black.svg";
import { ALERT_MODAL, REGISTER_MODAL, RESET_MODAL, MEMBER_UPDATED } from "../../store/actionTypes";
import * as Dialog from "@radix-ui/react-dialog";

const LoginModal = ({ showModal, closeModal, path }) => {
  useEffect(() => {
    dataLayer.push({
      event: "loginModal",
    });
  }, []);

  const [openModal, setOpenModal] = useState(showModal);
  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getCurrentUser = async () => {
    try {
      const currentUserResp = await currentUser();
      const user = get(currentUserResp, ["user"], null);
      dispatch({ type: MEMBER_UPDATED, member: user });
      posthog.identify(user.id, {
        email: user.email,
        name: user.name,
      });
      dataLayer.push({ event: "modalLoginSuccess", loginType: "native", currentPath: path, user_id: user.id });
      return user;
    } catch (err) {
      console.log("error--------", err);
    }
  };

  const hideModal = () => {
    setOpenModal(false);
    closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showRegisterModal = () => {
    setOpenModal(false);
    dispatch({
      type: REGISTER_MODAL,
      payload: true,
    });
  };

  const openResetPassword = () => {
    dispatch({
      type: RESET_MODAL,
      payload: true,
    });
  };

  const toggleAlertModal = () => {
    dispatch({
      type: ALERT_MODAL,
      payload: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});

    const loginDetails = {
      email: state.email,
      username: state.email,
      password: state.password,
    };

    if (state.email.length < 1 || state.password.length < 1) {
      setError({ message: "Please provide email and password" });
      return null;
    }

    setLoading(true);

    login(loginDetails)
      .then(async ({ user, message }) => {
        if (!user) {
          dataLayer.push({ event: "modalLoginFail", errorMsg: message, loginType: "native" });
          setError({ message });
          setLoading(false);
          return;
        }

        if (user["verification-status"]) {
          await getCurrentUser();
          setLoading(false);
          closeModal();
        } else {
          return sendVerificationLink(user.email, window.location.href)
            .then((res) => toggleAlertModal())
            .catch((error) => setError(error));
        }
      })
      .catch((err) => {
        dataLayer.push({ event: "modalLoginFail", errorMsg: err.message, loginType: "native" });
      });
  };

  const container = typeof window !== "undefined" && document.getElementById("portal");

  return (
    <Dialog.Root open={openModal} onOpenChange={hideModal}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay className="DialogOverlay" style={{ zIndex: loading ? 1 : 0 }} />
        <Dialog.Content
          className="DialogContent"
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            loading && e.preventDefault();
          }}
        >
          <div className="flex-container" style={{ borderTop: "10px solid #98c138" }}>
            <div className="flex-mobile-12 flex-desktop-12" styleName="modal-root">
              <div onClick={hideModal} style={{ float: "right" }} styleName="close-icon">
                <CloseIcon color="#000" style={{ height: "25px", width: "25px", cursor: "pointer" }} />
              </div>
              <img alt="afk logo" src={assetify(afkLogo)} styleName="afk-logo" />
              <h1 styleName="heading">Welcome Back!</h1>
              <div styleName="head-underline" />
              <div style={{ textAlign: "center", marginTop: "12px" }}>
                <SocialLogin setLoading={setLoading} />
              </div>
              <div styleName="sl-text">
                <div styleName="divider" />
                <div style={{ margin: "0px 2px" }}>OR LOGIN WITH EMAIL</div>
                <div styleName="divider" />
              </div>
              <form styleName="form-control" onSubmit={handleSubmit}>
                <div style={{ marginBottom: "8px" }}>
                  <input
                    name="email"
                    type="email"
                    label="Email"
                    value={state.email || ""}
                    autoComplete="off"
                    styleName="input"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div>
                  <input
                    name="password"
                    type="password"
                    label="Password"
                    autoComplete="off"
                    styleName="input"
                    value={state.password || ""}
                    onChange={handleInputChange}
                    style={{ width: "100%", marginBottom: "15px" }}
                    placeholder="Your Password"
                    required
                  />
                </div>
                {error && <p styleName="error">{error.message}</p>}
                <div style={{ textAlign: "right", marginBottom: "15px" }}>
                  <p styleName="forgot-link" onClick={openResetPassword}>
                    Forgot Password?
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button id="modal-native-login-btn" disabled={loading} styleName="login-button">
                    {loading ? "loading..." : "Log In"} &nbsp;
                    <RightArrowIcon style={{ height: "14px", width: "14px" }} color="#fff" />
                  </button>
                </div>
              </form>
              <div styleName="bottom-underline" />
              <div styleName="sign-up-div">
                <p
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "12px",
                    lineHeight: "21px",
                    color: "#b0b0b0",
                    fontWeight: 400,
                    letterSpacing: "0.015em",
                  }}
                >
                  Don't have an account yet?
                  <span
                    style={{ paddingLeft: "4px", textDecoration: "underline" }}
                    styleName="forgot-link"
                    onClick={showRegisterModal}
                  >
                    Sign up
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default LoginModal;
