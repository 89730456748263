import React from "react";
import "./icons.m.css";

export function MenuIcon() {
  return (
    <div style={{ cursor: "pointer", pointerEvents: "none" }} styleName="burger-icon">
      <svg viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
        <rect y="3.6001" width="24" height="2.4" fill="white" />
        <rect y="10.8003" width="24" height="2.4" fill="white" />
        <rect y="18" width="24" height="2.4" fill="white" />
      </svg>
    </div>
  );
}
