import React from "react";
import "./icons.m.css";

export function UpArrowIcon() {
  return (
    <div styleName="up-arrow-icon">
      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill="white">
        <rect fill="none" height="24" width="24" />
        <path d="M5,9l1.41,1.41L11,5.83V22H13V5.83l4.59,4.59L19,9l-7-7L5,9z" />
      </svg>
    </div>
  );
}
