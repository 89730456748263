import React from "react";
import "../LoginModal/login.m.css";
import { RightArrowIcon } from "../../basic/icons/right-arrow-icon";
import { CloseIcon } from "../../basic/icons/close-icon";
import { useDispatch } from "react-redux";
import * as Dialog from "@radix-ui/react-dialog";

const resetEmail = (data) => {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`https://${window.location.hostname}/api/auth/v1/users/forgot-password`)
    .post(data)
    .json((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

const ForgotPasswordModal = ({ showModal, closeModal }) => {
  const [openModal, setOpenModal] = React.useState(showModal);
  const [state, setState] = React.useState({
    email: "",
  });

  const dispatch = useDispatch();

  const hideModal = () => {
    setOpenModal(false);
    closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const details = {
      email: state.email,
    };
    resetEmail(details)
      .then((response) => {
        closeModal();
        dispatch({
          type: "TOGGLE_SNACKBAR",
          open: true,
          message: "Reset link sent",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const container = typeof window !== "undefined" && document.getElementById("portal");

  return (
    <Dialog.Root open={openModal} onOpenChange={hideModal}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent" onOpenAutoFocus={(e) => e.preventDefault()}>
          <div onClick={hideModal} style={{ position: "absolute", right: "10px", padding: "5px 6px" }}>
            <CloseIcon color="#000" style={{ height: "25px", width: "25px", cursor: "pointer" }} />
          </div>
          <div styleName="modal-container reg-root">
            <h1 styleName="heading">Reset Password</h1>
            <p styleName="sub-heading">Please enter your registered email address</p>
            <form
              style={{ marginBottom: "20px" }}
              styleName="form-control register-form-control"
              onSubmit={handleSubmit}
            >
              <div style={{ marginBottom: "20px" }}>
                <p styleName="label">Email*</p>
                <input
                  name="email"
                  type="email"
                  label="email"
                  value={state.email || ""}
                  autoComplete="off"
                  styleName="input"
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                  placeholder="Enter email here..."
                  required
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button styleName="login-button">
                  Send &nbsp;
                  <RightArrowIcon style={{ height: "14px", width: "14px" }} color="#fff" />
                </button>
              </div>
            </form>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
export default ForgotPasswordModal;
