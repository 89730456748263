import React from "react";
import "./icons.m.css";

export function RightArrowIcon({ cssName = "right-arrow-icon", color = "none", style, height = "10" }) {
  return (
    <div style={style} styleName={cssName}>
      <svg style={{ height }} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
        <rect fill={color} />
        <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" fill={color} />
      </svg>
    </div>
  );
}
