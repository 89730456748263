import React, { useEffect } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { LogoutIcon } from "../../basic/icons/logout-icon";
import { SettingsIcon } from "../../basic/icons/settings-icon";
import { formatDateIST } from "../../helper/date-helper";
import { getLoginMethod } from "../../helper/helper";

import "./user-panel.m.css";
import { useDispatch } from "react-redux";

function UserPanel({ user, logout }) {
  useEffect(() => {
    const regDate = formatDateIST(user["created-at"], "DD MMM YYYY, hh:mm A");
    const loginMethod = getLoginMethod(user["verification-status"]);

    localStorage.setItem("isRegistered", "true");
    localStorage.setItem("regDate", regDate);
    dataLayer.push({
      user_id: user.id,
      loginStatus: "Logged-In",
      userType: "Registered",
      registrationStatus: "true",
      regDate: regDate,
      userEmail: user.email,
      userName: user.name,
      loginMethod: loginMethod,
      event: "userLogin",
    });
  }, []);

  let avatarUrl =
    "https://thumbor-stg.assettype.com/afkgamingtestdemo2/2021-03/6e071c0e-cb86-4afc-98e4-e647dcbc5ca5/profile.jpg";
  if (user && user["avatar-url"]) {
    avatarUrl = user["avatar-url"];
  }

  const dispatch = useDispatch();

  const container = typeof window !== "undefined" && document.getElementById("portal");

  const navigateTo = (url) => global.app.navigateToPage(dispatch, url);

  return (
    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <img id="dd-img" alt="avatar" src={avatarUrl} styleName="user-circle" height="45" width="45" />
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal container={container}>
          <DropdownMenu.Content styleName="DropdownMenuContent" sideOffset={5}>
            <DropdownMenu.Item styleName="DropdownMenuItem" disabled>
              {user.email}
            </DropdownMenu.Item>

            <DropdownMenu.Separator styleName="DropdownMenuSeparator" />

            <DropdownMenu.Item styleName="DropdownMenuItem" onClick={() => navigateTo("/settings")}>
              <span styleName="icon-slot">
                <SettingsIcon />
              </span>
              Settings
            </DropdownMenu.Item>

            <DropdownMenu.Separator styleName="DropdownMenuSeparator" />

            <DropdownMenu.Item styleName="DropdownMenuItem" onClick={logout}>
              <div styleName="icon-slot">
                <LogoutIcon />
              </div>
              Log out
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}

export { UserPanel };
