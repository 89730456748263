import React from "react";

import "./followsocials.m.css";
import SocialFollowIcons from "../../atoms/social-follow-icons";

const FollowSocials = () => {
  return (
    <div styleName="social-follow-box">
      <div styleName="social-container">
        <hr styleName="social-hr" />
        <h2 styleName="follow-heading">Follow us on social media</h2>
      </div>
      <SocialFollowIcons />
    </div>
  );
};

export default FollowSocials;
