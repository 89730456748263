//import { removeSpacesAndLowerCase } from "./helper";

export const sections = {
  global: { id: 7, name: "Global", color: "#ff9900", slug: "global" },
  dota2: { id: 2, name: "Dota 2", color: "#ff4318", slug: "dota2" },
  csgo: { id: 1, name: "CSGO", color: "#ffba00", slug: "csgo" },
  mobileesports: { id: 3, name: "Mobile Esports", color: "#1DA1DC", slug: "mobileesports" },
  esports: { id: 0, name: "Esports", color: "#9ACA3C", slug: "esports" },
  gaming: { id: 6, name: "Gaming", color: "#7E21CE", slug: "gaming" },
  blockchaingames: { id: 5, name: "Blockchain Games", color: "#243CEA", slug: "blockchaingames" },
  premium: { id: -1, name: "Premium", color: "#ff9900", slug: "premium" },
};

export const getSectionArray = () => {
  const sectionsList = [];
  Object.keys(sections).forEach((data) => {
    sectionsList.push(sections[data]);
  });

  return sectionsList;
};

export const getSectionName = (id) => {
  switch (id) {
    case sections.csgo.id:
      return sections.csgo.name;
    case sections.dota2.id:
      return sections.dota2.name;
    case sections.mobileesports.id:
      return sections.mobileesports.name;
    case sections.esports.id:
      return sections.esports.name;
    case sections.blockchaingames.id:
      return sections.blockchaingames.name;
    case sections.gaming.id:
      return sections.gaming.name;
    default:
      return "Esports";
  }
};

export const getSectionForUrl = (id) => {
  switch (id) {
    case sections.csgo.id:
      return sections.csgo.slug;
    case sections.dota2.id:
      return sections.dota2.slug;
    case sections.mobileesports.id:
      return sections.mobileesports.slug;
    case sections.blockchaingames.id:
      return sections.blockchaingames.slug;
    case sections.gaming.id:
      return sections.gaming.slug;
    default:
      return sections.esports.slug;
  }
};

export const getSectionIdFromSlug = (slug) => {
  switch (slug) {
    case sections.csgo.slug:
      return sections.csgo.id;
    case sections.dota2.slug:
      return sections.dota2.id;
    case sections.mobileesports.slug:
      return sections.mobileesports.id;
    case sections.blockchaingames.slug:
      return sections.blockchaingames.id;
    case sections.gaming.slug:
      return sections.gaming.id;
    default:
      return sections.esports.id;
  }
};

export const getSectionSlugFromName = (name) => {
  switch (name) {
    case sections.csgo.name:
    case "CSGO":
    case "csgo":
    case "CS:GO":
    case "cs:go":
      return sections.csgo.slug;
    case sections.dota2.name:
    case "dota2":
    case "Dota2":
      return sections.dota2.slug;
    case sections.mobileesports.name:
    case "MobileEsports":
    case "mobileesports":
      return sections.mobileesports.slug;
    case "Premium":
    case "premium":
      return "premium";
    case "BlockchainGames":
    case "blockchaingames":
      return sections.blockchaingames.slug;
    case "Gaming":
    case "gaming":
      return sections.gaming.slug;
    default:
      return sections.esports.slug;
  }
};

export const getSectionNameFromSlug = (slug) => {
  switch (slug) {
    case sections.csgo.slug:
      return sections.csgo.name;
    case sections.dota2.slug:
      return sections.dota2.name;
    case sections.mobileesports.slug:
      return sections.mobileesports.name;
    case sections.esports.slug:
      return sections.esports.name;
    case sections.blockchaingames.slug:
      return sections.blockchaingames.name;
    case sections.gaming.slug:
      return sections.gaming.slug;
    default:
      return "Esports";
  }
};

export const getSectionNameFromCollection = (collectionName) => {
  let section = "";
  collectionName = collectionName.toLowerCase();
  if (collectionName.includes("mobile")) {
    section = "Mobile Esports";
  } else if (collectionName.includes("dota 2")) {
    section = "Dota 2";
  } else if (collectionName.includes("csgo")) {
    section = "CSGO";
  } else if (collectionName.includes("blockchain")) {
    section = "Blockchain Games";
  } else if (collectionName.includes("gaming")) {
    section = "Gaming";
  } else {
    section = "Esports";
  }

  return section;
};

export const getSectionFromConfig = (sectionId, sectionArray) => {
  const section = sectionArray.find((data) => {
    return sectionId === data.id;
  });

  return section;
};

export const categories = [
  { name: "News", slug: "news" },
  { name: "Guide", slug: "guide" },
  { name: "Originals", slug: "originals" },
  { name: "Event", slug: "event" },
];
export const gamingCategories = [
  { name: "Honkai: Star Rail", slug: "honkai-star-rail" },
  { name: "Genshin Impact", slug: "genshin-impact" },
  { name: "FIFA", slug: "fifa" },
  { name: "Diablo 4", slug: "diablo-4" },
  { name: "General", slug: "general" },
  { name: "Roblox", slug: "roblox" },
  { name: "Palworld", slug: "palworld" },
];
export const premiumCategories = [
  { name: "Dota 2", slug: "dota2" },
  { name: "Valorant", slug: "valorant" },
  { name: "MLBB", slug: "mlbb" },
  { name: "Wild Rift", slug: "wildrift" },
  { name: "PUBGM", slug: "pubgm" },
  { name: "Esports", slug: "esports" },
];

export const getNestedSectionsFromConfig = (sections) => {
  const parentList = [];

  sections.forEach((item) => {
    const parentId = item["parent-id"];
    if (!parentId) {
      const displayName = item["display-name"];

      parentList.push({ id: item.id, name: displayName, childIds: [] });
    }
  });

  sections.forEach((item) => {
    let parentId = item["parent-id"];
    if (parentId) {
      let parentItem = parentList.find((obj) => {
        return obj.id === parentId;
      });
      parentItem.childIds.push(item.id);
    }
  });

  return parentList;
};
