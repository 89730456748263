import React from "react";
import "../LoginModal/login.m.css";
import { CloseIcon } from "../../basic/icons/close-icon";
import * as Dialog from "@radix-ui/react-dialog";

const AlertModal = ({ showModal, closeModal }) => {
  const [openModal, setOpenModal] = React.useState(showModal);

  const hideModal = () => {
    setOpenModal(false);
    closeModal();
  };

  const container = typeof window !== "undefined" && document.getElementById("portal");

  return (
    <Dialog.Root open={openModal} onOpenChange={hideModal}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent" onOpenAutoFocus={(e) => e.preventDefault()}>
          <div onClick={hideModal} style={{ float: "right", padding: "5px 6px" }}>
            <CloseIcon color="#000" style={{ height: "25px", width: "25px", cursor: "pointer" }} />
          </div>
          <div styleName="modal-container">
            <h1 styleName="heading">Email Verification</h1>
            <p styleName="sub-heading">Verification link sent successfully to your email address</p>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AlertModal;
