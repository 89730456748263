import React from "react";
import { Link } from "@quintype/components";
import "./footer.m.css";
import { UpArrowIcon } from "../../basic/icons/up-arrow-icon";
import { useSelector } from 'react-redux';
import { utmConsistencyHelper } from '../../helper/helper';
import get from "lodash/get";

function Footer() {

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  const currentUrl = useSelector((state) => get(state, ["qt", "currentPath"], "/"));

  return (
    <div styleName="footer-root">
      <div style={{ maxWidth: "1200px", width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", position: "relative" }}>
          <div onClick={scrollToTop} styleName="scroll-to-top">
            <UpArrowIcon />
          </div>
        </div>
      </div>

      <div styleName="inner-root">
        <div styleName="col-container left">
          <div styleName="afk-title">AFK Gaming</div>
          <div styleName="divider" />
          <div styleName="afk-desc">
            AFK Gaming is your go-to destination for quality esports content. Our writers strive to provide accurate,
            trustworthy and timely esports news, gaming guides, player interviews, tournament coverage and original
            stories from around the world.
          </div>
        </div>

        <div styleName="row-container right">
          <div styleName="col-container left-col">
            <Link href={utmConsistencyHelper("/guidelines", currentUrl)} styleName="link">
              Editorial Guidelines
            </Link>
            <Link href={utmConsistencyHelper("/editorial_team", currentUrl)} styleName="link">
              Editorial Team
            </Link>
            <Link href={utmConsistencyHelper("/advertise", currentUrl)} styleName="link">
              Advertise With Us
            </Link>
            <Link href={utmConsistencyHelper("/archive/articles", currentUrl)} styleName="link">
              Archive
            </Link>
          </div>
          <div styleName="col-container right-col">
            <Link href={utmConsistencyHelper("/about", currentUrl)} styleName="link">
              About Us
            </Link>
            <Link href={utmConsistencyHelper("/contact", currentUrl)} styleName="link">
              Contact Us
            </Link>
            <Link href={utmConsistencyHelper("/services", currentUrl)} styleName="link">
              Services
            </Link>
            <Link href={utmConsistencyHelper("/rssfeed", currentUrl)} styleName="link">
              RSS
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div styleName="bot-divider" />
        <div styleName="policy-xs">
          <Link href={utmConsistencyHelper("/disclaimer", currentUrl)} styleName="link2 link2-text">
            Disclaimer
          </Link>
          <Link href={utmConsistencyHelper("/policy", currentUrl)} styleName="link2 link2-text">
            Privacy Policy
          </Link>
          <Link href={utmConsistencyHelper("/legal", currentUrl)} styleName="link2">
            Terms of Use
          </Link>
        </div>
        <div styleName="bot-divider" />
        <div styleName="copyright-panel">
          <div styleName="copyright">(c) {new Date().getFullYear()} AFK Gaming - All Rights Reserved</div>
        </div>
      </div>
    </div >
  );
}

export { Footer };
