export const STORE_TRENDING_STORIES = "STORE_TRENDING_STORIES";
export const REGISTER_MODAL = "REGISTER_MODAL";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const ALERT_MODAL = "ALERT_MODAL";
export const RESET_MODAL = "RESET_MODAL";
export const SET_USER = "SET_USER";
export const TOGGLE_SNACKBAR = "TOGGLE_SNACKBAR";
export const ENABLE_GOOGLE_ONETAP = "ENABLE_GOOGLE_ONETAP";
export const ENABLE_LOGIN = "ENABLE_LOGIN";
export const MEMBER_UPDATED = "MEMBER_UPDATED";
export const SET_CURRENT_STORY = "SET_CURRENT_STORY";
