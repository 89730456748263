import React, { useEffect } from "react";
import "../LoginModal/login.m.css";
import { RightArrowIcon } from "../../basic/icons/right-arrow-icon";
import { CloseIcon } from "../../basic/icons/close-icon";
import { useDispatch } from "react-redux";
import { register, sendVerificationLink } from "@quintype/bridgekeeper-js";
import * as Dialog from "@radix-ui/react-dialog";

const RegisterModal = ({ showModal, closeModal, ...props }) => {
  useEffect(() => {
    dataLayer.push({
      event: "registerModal",
    });
  }, []);
  const [openModal, setOpenModal] = React.useState(showModal);
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const hideModal = () => {
    setOpenModal(false);
    closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showLoginModal = () => {
    setOpenModal(false);
    dispatch({
      type: "LOGIN_MODAL",
      payload: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.password !== state.confirmPassword) {
      return setError("Password does not match");
    } else if (!state.username && !state.email && !state.password && !state.name) {
      setError("Please provide your name, email and password");
      return;
    } else if (!state.email) {
      setError("Please provide your email");
      return;
    } else if (!state.password) {
      setError("Please provide password");
      return;
    }

    const registerDetails = {
      username: state.email,
      name: state.name,
      email: state.email,
      password: state.password,
      "dont-login": true,
    };

    setLoading(true);

    register(registerDetails)
      .then(({ user, message }) => {
        if (!user && message === "User Already exists") {
          setLoading(false);
          dataLayer.push({ event: "signupFail", errorMsg: message, signupType: "afk" });
          return setError("User Already Exists");
        }
        sendVerificationLink(user.email, window.location.href);
        dataLayer.push({ event: "signupSuccess", signupType: "afk" });
        setError("");
        // const data = {
        //   email: user.email,
        //   id: user.id,
        //   username: state.username
        // };
        // afkRegisterAPI(data);
        dispatch({
          type: "ALERT_MODAL",
          payload: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 409) {
          dataLayer.push({ event: "signupFail", errorMsg: "Email already exists", signupType: "afk" });
          setError("Email already exists");
        } else {
          dataLayer.push({ event: "signupFail", errorMsg: "Something went wrong", signupType: "afk" });
          setError(err, "Something went wrong.");
        }
      });
  };

  const container = typeof window !== "undefined" && document.getElementById("portal");

  return (
    <Dialog.Root open={openModal} onOpenChange={hideModal}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent" onOpenAutoFocus={(e) => e.preventDefault()}>
          <div onClick={hideModal} style={{ position: "absolute", right: "10px", padding: "5px 6px" }}>
            <CloseIcon color="#000" style={{ height: "25px", width: "25px", cursor: "pointer" }} />
          </div>
          <div styleName="reg-root">
            <h1 styleName="heading">Hello there!</h1>
            <p styleName="sub-heading">Register for AFK Gaming</p>
            <form styleName="register-form-control" onSubmit={handleSubmit}>
              <div style={{ marginBottom: "20px" }} styleName="input-container">
                <p styleName="label">Name*</p>
                <input
                  name="name"
                  type="text"
                  label="Name"
                  value={state.name || ""}
                  autoComplete="off"
                  styleName="input register-input"
                  onChange={handleInputChange}
                  placeholder="Enter name here..."
                  required
                />
              </div>
              <div style={{ marginBottom: "20px" }} styleName="input-container">
                <p styleName="label">Email*</p>
                <input
                  name="email"
                  type="email"
                  label="Email"
                  value={state.email || ""}
                  autoComplete="off"
                  styleName="input register-input"
                  onChange={handleInputChange}
                  placeholder="Enter email here..."
                  required
                />
              </div>
              <div style={{ marginBottom: "20px" }} styleName="input-container">
                <p styleName="label">Password*</p>
                <input
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="off"
                  styleName="input register-input"
                  value={state.password || ""}
                  onChange={handleInputChange}
                  placeholder="Enter password here..."
                  required
                />
              </div>
              <div style={{ marginBottom: "20px" }} styleName="input-container">
                <p styleName="label">Confirm Password*</p>
                <input
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  autoComplete="off"
                  styleName="input register-input"
                  value={state.confirmPassword || ""}
                  onChange={handleInputChange}
                  placeholder="Enter password again..."
                  required
                />
              </div>
              <div style={{ textAlign: "center", marginBottom: "12px" }}>
                <p styleName="register-text">
                  Already have an account yet?{" "}
                  <span styleName="forgot-link" onClick={showLoginModal} style={{ opacity: 1 }}>
                    Login here
                  </span>
                </p>
              </div>
              <div styleName="button-container">
                <button id="modal-signup-btn" disabled={loading} styleName="login-button">
                  Register &nbsp;
                  <RightArrowIcon style={{ height: "14px", width: "14px" }} color="#fff" />
                </button>
              </div>
            </form>
            {error && (
              <div
                style={{ fontFamily: "Open Sans", fontSize: "14px", fontWeight: 500, color: "red", marginTop: "10px" }}
              >
                {error}
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default RegisterModal;
