import React from "react";
import { connect } from "react-redux";
import NavBar from "./nav-bar";
import Snackbar from "../molecules/snackbar/snackbar";
import Cookies from "js-cookie";
import SubscriptionPopup from "../atoms/SubscriptionPopup";
import get from "lodash/get";
import { getSubscriptionForUser } from "../helper/helper";

import "./header.m.css";

class HeaderBase extends React.Component {
  state = {
    clickedTitle: 0,
    csgo: true,
    dota: true,
    mobileesports: true,
    esports: true,
    titleArray: [],
    isIndiaHub: false,
    subscriptionPopup: false,
  };

  componentDidMount = () => {
    if (window.location.href.includes("?access-token")) {
      window.location.href = window.location.pathname;
    }

    this.getSectionFromPath(this.props);

    getSubscriptionForUser(this.props.accessTypeKey, this.props.accessTypeIntegrationId).then((res) => {
      if (res.subscriptions) return;
      if (this.props.pageType === "story-page" && !this.props.currentPath.includes("global")) {
        this.setState({ subscriptionPopup: true });
      }
    });

    dataLayer.push({
      registrationStatus: localStorage.getItem("isRegistered") || "false",
      lastPaywallViewDate: Cookies.get("lastPaywallViewDate") || null,
      paywallViewCount: Cookies.get("paywallViewCount") || 0,
      regDate: localStorage.getItem("regDate"),
      loginStatus: "Logged-Out",
      userType: "Unknown",
      event: "appLoaded",
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.titleArray !== prevProps.titleArray && this.state.titleArray.length !== 0) {
      this.requestPermission();
    }
  }

  shouldComponentUpdate = (nextProps) => {
    if (this.props.currentPath !== nextProps.currentPath) {
      this.getSectionFromPath(nextProps);
      this.handleIndiaHub(nextProps);
    }

    return true;
  };

  onTitleClick = (id) => {
    this.setState({ clickedTitle: id });
  };

  onHomeClick = () => {
    this.setState({ clickedTitle: 0 });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleScrollClick = (isLeft) => {
    let element = document.getElementById("secondary-header-bar");
    if (isLeft) {
      element.scrollBy({
        top: 0,
        left: -100,
        behavior: "smooth",
      });
    } else {
      element.scrollBy({
        top: 0,
        left: +100,
        behavior: "smooth",
      });
    }
  };

  handleIndiaHub = (props) => {
    const currentPath = props.currentPath;

    if (currentPath.includes("/indian-esports")) {
      this.setState({ isIndiaHub: true });
    }
  };

  getSectionFromPath = (props) => {
    const currentPath = props.currentPath;
    let sectionName = "";

    if (currentPath.includes("/premium")) {
      this.setState({ clickedTitle: 5 });
      sectionName = "Premium";
    } else if (currentPath.includes("/dota2")) {
      this.setState({ clickedTitle: 2 });
      sectionName = "Dota 2";
    } else if (currentPath.includes("/csgo")) {
      this.setState({ clickedTitle: 3 });
      sectionName = "CS:GO";
    } else if (currentPath.includes("/mobile")) {
      this.setState({ clickedTitle: 4 });
      sectionName = "Mobile Esports";
    } else if (currentPath.includes("/esports")) {
      this.setState({ clickedTitle: 1 });
      sectionName = "Esports";
    } else if (currentPath.includes("/gaming")) {
      this.setState({ clickedTitle: 9 });
      sectionName = "Blockchain Games";
    } else if (currentPath.includes("/indian-esports")) {
      this.setState({ clickedTitle: 8 });
    } else {
      this.setState({ clickedTitle: 0 });
    }

    if (sectionName) {
      dataLayer.push({ lastSectionVisited: sectionName });
    }
  };

  render() {
    return (
      <div>
        <div styleName="container black">
          <NavBar {...this.props} homeClick={this.onHomeClick} />
        </div>
        <Snackbar />
        {this.state.subscriptionPopup && (
          <SubscriptionPopup pageType={this.props.pageType} currentPath={this.props.currentPath} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentPath: state.qt.currentPath,
    pageType: state.qt.pageType,
    accessTypeIntegrationId: get(state, ["qt", "config", "publisher-attributes", "accesstype", "integrationId"], ""),
    accessTypeKey: get(state, ["qt", "config", "publisher-attributes", "accesstype", "key"], ""),
  };
}

export const Header = connect(mapStateToProps)(HeaderBase);
