import React from "react";
import "./icons.m.css";

export function LogoutIcon() {
  return (
    <div styleName="logout-icon">
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6667 15.8333V15.7641C10.685 16.335 9.54833 16.6666 8.33333 16.6666C4.6575 16.6666 1.66667 13.6758 1.66667 9.99996C1.66667 6.32413 4.6575 3.33329 8.33333 3.33329C9.54833 3.33329 10.685 3.66496 11.6667 4.23579V2.36496C10.645 1.91829 9.52 1.66663 8.33333 1.66663C3.73167 1.66663 0 5.39746 0 9.99996C0 14.6025 3.73167 18.3333 8.33333 18.3333C9.52 18.3333 10.645 18.0816 11.6667 17.635V15.8333ZM15 7.83996L17.4692 9.99996L15 12.16V10.8333H8.33333V9.16663H15V7.83996ZM13.3333 4.16663V7.49996H6.66667V12.5H13.3333V15.8333L20 9.99996L13.3333 4.16663V4.16663Z"
          fill="#0D1319"
        />
      </svg>
    </div>
  );
}
