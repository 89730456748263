import React from "react";
import { CloseIcon } from "../../basic/icons/close-icon";
import "./drawer.m.css";
import { Search } from "../../basic/icons/search";
import { Link } from "@quintype/components";
import { getSectionArray } from "../../helper/section-helper";
import { SectionMolecule } from "./section-molecule";
// import { ChildItem } from "./child-item";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import * as actionTypes from "../../store/actionTypes";
import FollowSocials from "../../molecules/FollowSocials";
import { utmConsistencyHelper } from "../../helper/helper";

const getSearchPath = (query) => {
  return "/search?q=" + query;
};

const section = [
  {
    name: "Global",
    slug: "global",
    hasChildren: false,
  },
  {
    name: "Free Reads",
    slug: "free-reads",
    hasChildren: false,
  },
  {
    name: "Archived",
    slug: "archived",
    hasChildren: true,
  },
];

function Drawer(props) {
  let [searchText, setSearchText] = React.useState("");

  const enableLogin = useSelector((state) => get(state, ["authReducer", "enableLogin"], false));
  const member = useSelector((state) => get(state, ["member"], null));
  const currentUrl = useSelector((state) => get(state, ["qt", "currentPath"], "/"));
  const dispatch = useDispatch();

  function handleChange(e) {
    setSearchText(e.target.value);
  }

  function handleKeyUp(e) {
    if (e.keyCode === 13) {
      document.getElementById("search-btn-dw").click();
    }
  }

  function openLoginModal() {
    dispatch({
      type: actionTypes.LOGIN_MODAL,
      payload: true,
    });
  }

  let sectionDiv = section.map((data, index) => {
    return (
      <SectionMolecule
        key={index}
        id={index}
        label={data.name}
        slug={data.slug}
        click={props.click}
        hasChildren={data.hasChildren}
      />
    );
  });

  const searchPath = getSearchPath(searchText);

  return (
    <div styleName="drawer-root">
      <div styleName="backdrop" onClick={props.click} />
      <div id="nav-drawer" styleName="drawer">
        <div styleName="close-root" onClick={props.click}>
          <div styleName="nav-title">Navigation</div>
          <CloseIcon color="#000000" width={24} height={24} />
        </div>
        <div styleName="search-box">
          <input
            placeholder="Search articles, videos &amp; more..."
            styleName="search-text"
            value={searchText}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          <Link id="search-btn-dw" href={utmConsistencyHelper(searchPath, currentUrl)} callback={props.click}>
            <Search cssName="search-icon-drawer" color="#0D131940" />
          </Link>
        </div>
        <div styleName="content-box">
          {sectionDiv}
          {enableLogin && (
            <React.Fragment>
              {member && member["verification-status"] ? (
                <div styleName="content-box">
                  {/* <ChildItem cssName={"text-2"} label="Profile" path={`/user/${member.username}`} />
                  <div styleName="section-divider" />
                  <ChildItem
                    cssName={"text-2"}
                    label="Notifications"
                    path={`/user/${member.username}/notifications`}
                  />
                  <div styleName="section-divider" />
                  <ChildItem cssName={"text-2"} label="Settings" path={`/settings`} />
                  <div styleName="section-divider" /> */}
                  <label styleName="label-text" onClick={props.logout}>
                    Logout
                  </label>
                  <div styleName="section-divider" />
                </div>
              ) : (
                <div styleName="content-box">
                  <label styleName="label-text" onClick={openLoginModal}>
                    Login
                  </label>
                  <div styleName="section-divider" />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div styleName="social-box">
          <FollowSocials />
        </div>
      </div>
    </div>
  );
}

export default Drawer;
