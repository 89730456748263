import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    // Home
    [PAGE_TYPE.HOME_PAGE]: { chunk: "home", component: "HomePage" },
    //Section
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "section", component: "SectionPage" },
    [PAGE_TYPE.LATEST_STORIES_PAGE]: { chunk: "section", component: "SectionPage" },
    [PAGE_TYPE.FREE_READS_PAGE]: { chunk: "section", component: "SectionPage" },
    //List
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.LATEST_COLLECTIONS_PAGE]: { chunk: "list", component: "CategoryPage" },
    [PAGE_TYPE.ARCHIVE_LISTING]: { chunk: "list", component: "ArchiveListing" },
    //Story
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.ARCHIVED_ARTICLES]: { chunk: "story", component: "ArchivedArticles" },
    //Footer
    [PAGE_TYPE.ABOUT_US_PAGE]: { chunk: "footerItems", component: "AboutUsPage" },
    [PAGE_TYPE.EDITORIAL_TEAM_PAGE]: { chunk: "footerItems", component: "EditorialTeam" },
    [PAGE_TYPE.RSS_PAGE]: { chunk: "footerItems", component: "RSSFeedPage" },
    [PAGE_TYPE.ADVERTISE_PAGE]: { chunk: "footerItems", component: "AdvertisePage" },
    [PAGE_TYPE.DISCLAIMER_PAGE]: { chunk: "footerItems", component: "DisclaimerPage" },
    [PAGE_TYPE.CONTACT_US]: { chunk: "footerItems", component: "ContactUs" },
    [PAGE_TYPE.TERMS_OF_USE]: { chunk: "footerItems", component: "TermsOfUse" },
    [PAGE_TYPE.EDITORIAL_GUIDELINES]: { chunk: "footerItems", component: "EditorialGuidelines" },
    [PAGE_TYPE.SERVICES_PAGE]: { chunk: "footerItems", component: "Services" },
    [PAGE_TYPE.POLICY_PAGE]: { chunk: "footerItems", component: "PrivacyPolicy" },
    [PAGE_TYPE.LEADERBOARD_POLICY]: { chunk: "footerItems", component: "LeaderboardPolicy" },
    [PAGE_TYPE.REFUND_POLICY]: { chunk: "footerItems", component: "RefundPolicy" },
    //Event
    [PAGE_TYPE.EVENT_PAGE]: { chunk: "event", component: "EventsPage" },
    [PAGE_TYPE.EVENT_SCHEDULE_PAGE]: { chunk: "event", component: "EventsPage" },
    [PAGE_TYPE.EVENT_ROSTER_PAGE]: { chunk: "event", component: "EventsPage" },
    [PAGE_TYPE.EVENT_RESULTS_PAGE]: { chunk: "event", component: "EventsPage" },
    [PAGE_TYPE.EVENTS_LISTING]: { chunk: "event", component: "EventsListing" },
    //Profile
    [PAGE_TYPE.USER_PAGE]: { chunk: "profile", component: "UserPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "profile", component: "AuthorPage" },
    [PAGE_TYPE.USER_SETTINGS]: { chunk: "profile", component: "SettingsPage" },
    [PAGE_TYPE.RESET_PASSWORD]: { chunk: "profile", component: "ResetPassword" },
    //Bundle
    [PAGE_TYPE.BUNDLE_PAGE]: { chunk: "bundle", component: "BundlePage" },
    // Visual Story
    [PAGE_TYPE.VISUAL_STORY_LISTING]: { chunk: "visual", component: "VisualStoryListing" },
    //India Hub
    [PAGE_TYPE.INDIA_HUB_HOME]: { chunk: "indiaHub", component: "IndiaHubHomePage" },
    [PAGE_TYPE.INDIA_HUB_SECTION]: { chunk: "indiaHub", component: "IndiaHubSectionPage" },
    // Leaderboard
    [PAGE_TYPE.LEADERBOARD_PAGE]: { chunk: "leaderboard", component: "LeaderboardPage" },
    // Subscription
    [PAGE_TYPE.SUBSCRIPTION_PAGE]: { chunk: "subscription", component: "SubscriptionPage" },
    [PAGE_TYPE.CHECKOUT_PAGE]: { chunk: "subscription", component: "CheckoutPage" },
    [PAGE_TYPE.PROMOTIONAL_PAGE]: { chunk: "subscription", component: "PromotionalPage" },
    [PAGE_TYPE.INTL_PROMOTIONAL_PAGE]: { chunk: "subscription", component: "InternationalPromotionPage" },
    [PAGE_TYPE.SUCCESS_PAGE]: { chunk: "subscription", component: "PaymentSuccessPage" },
    //Default
    default: { chunk: "list", component: "NotFoundPage" },
  },
  {
    home: () => import(/* webpackChunkName: "home" */ "./component-bundles/home.js"),
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    bundle: () => import(/* webpackChunkName: "bundle" */ "./component-bundles/bundle.js"),
    profile: () => import(/* webpackChunkName: "profile" */ "./component-bundles/profile.js"),
    footerItems: () => import(/* webpackChunkName: "footerItems" */ "./component-bundles/footer.js"),
    event: () => import(/* webpackChunkName: "event" */ "./component-bundles/event.js"),
    section: () => import(/* webpackChunkName: "section" */ "./component-bundles/section.js"),
    visual: () => import(/* webpackChunkName: "visual" */ "./component-bundles/visual.js"),
    indiaHub: () => import(/* webpackChunkName: "indiaHub" */ "./component-bundles/india-hub.js"),
    leaderboard: () => import(/* webpackChunkName: "leaderboard" */ "./component-bundles/leaderboard.js"),
    subscription: () => import(/* webpackChunkName: "subscription" */ "./component-bundles/subscription.js"),
  }
);

export { pickComponent, getChunkName };
