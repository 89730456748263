import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import { Header } from "../isomorphic/components/header";
import { Footer } from "../isomorphic/components/layouts/footer";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/LoadingIndicator";
import OneTapLogin from "../isomorphic/components/OneTapLogin";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(Header, "header", store, hydrate);
  renderComponent(Footer, "footer", store, hydrate);
  renderComponent(OneTapLogin, "onetap", store, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell,
  });
}
