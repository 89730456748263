import React from "react";
import "./parent-item.m.css";
import { KeyboardRightIcon } from "../../../basic/icons/keyboard-right-arrow";
import { Link } from "@quintype/components";

function ParentItem(props) {
  if (props.link) {
    return (
      <div styleName="root">
        <Link
          styleName="text"
          href={`/${props.slug}`}
          callback={props.click}
          style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "0" }}
        >
          <KeyboardRightIcon />
          {props.label}
        </Link>
      </div>
    );
  }

  return (
    <div styleName="root">
      <div style={{ transform: props.expanded ? "rotate(90deg)" : "rotate(0deg)", transition: "all 0.3s" }}>
        <KeyboardRightIcon />
      </div>
      <div styleName="text">{props.label}</div>
    </div>
  );
}

export { ParentItem };
