import React, { useState, useEffect } from "react";
import FacebookIcon from "../../basic/icons/fb-icon";
import GoogleIcon from "../../basic/icons/google-icon";
import { withFacebookLogin, withGoogleLogin, currentUser } from "@quintype/bridgekeeper-js";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { MEMBER_UPDATED } from "../../store/actionTypes";
import "./social.m.css";

const SocialLogin = ({ setLoading }) => {
  const [currentLocation, setCurrentLocation] = useState("/");
  const googleAppId = useSelector((state) => get(state, ["qt", "config", "publisher-attributes", "google_app_id"], ""));
  const facebookAppId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "facebook_app_id"], "")
  );

  const dispatch = useDispatch();

  const getCurrentUser = async () => {
    try {
      const currentUserResp = await currentUser();
      const user = get(currentUserResp, ["user"], null);
      dispatch({ type: MEMBER_UPDATED, member: user });
      posthog.identify(user.id, {
        email: user.email,
        name: user.name,
      });
      return user;
    } catch (err) {
      console.log("error--------", err);
    }
  };

  useEffect(() => {
    const location = window.location.href;
    location && setCurrentLocation(location);
  }, []);

  const socialLogin = (e, login, type) => {
    e.preventDefault();
    setLoading(true);
    login()
      .then(async () => {
        dataLayer.push({ event: "modalLoginSuccess", loginType: type });
        await getCurrentUser();
        setLoading(false);
      })
      .catch((err) => {
        dataLayer.push({ event: "modalLoginFail", errorMsg: err.message, loginType: type });
        setLoading(false);
      });
  };

  const FaceBookLogin = () => {
    const { login } = withFacebookLogin({
      appId: facebookAppId,
      scope: "email",
      emailMandatory: true,
      redirectUrl: currentLocation,
    });

    return (
      <div id="modal-fb-login-btn" onClick={(e) => socialLogin(e, login, "facebook")} styleName="login-button fb-btn">
        <FacebookIcon />
        <span styleName="fb-text"></span>
      </div>
    );
  };

  const GoogleLogin = () => {
    const { login } = withGoogleLogin({
      clientId: googleAppId,
      scope: "email",
      emailMandatory: true,
      redirectUrl: currentLocation,
    });

    return (
      <div
        id="modal-google-login-btn"
        onClick={(e) => socialLogin(e, login, "google")}
        styleName="login-button google-btn"
      >
        <GoogleIcon />
        <span styleName="google-text"></span>
      </div>
    );
  };

  return (
    <div styleName="root">
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <GoogleLogin />
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <FaceBookLogin />
      </div>
    </div>
  );
};

export default SocialLogin;
