import * as actionTypes from "./actionTypes";
import { trendingCollectionHelper } from "../helper/helper";
const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

const storyState = {
  trendingStories: [],
  currentStory: {},
  currentIndex: 0,
};

const storeTrendingStories = (state, action) => {
  const story = action.payload;
  const section = action.section;

  let storySection;

  if (section["parent-id"]) {
    storySection = section.collection.slug.split("-")[1];
  } else {
    storySection = section.slug;
  }

  const trending = trendingCollectionHelper(story.collection, storySection);

  return updateObject(state, {
    trendingStories: trending,
  });
};

const setCurrentStory = (state, action) => {
  return updateObject(state, {
    currentStory: action.story,
    currentIndex: action.index,
  });
};

const storyReducer = (state = storyState, action) => {
  switch (action.type) {
    case actionTypes.STORE_TRENDING_STORIES:
      return storeTrendingStories(state, action);
    case actionTypes.SET_CURRENT_STORY:
      return setCurrentStory(state, action);
    default:
      return state;
  }
};

const authState = {
  loginModal: false,
  registerModal: false,
  otpModal: false,
  alertModal: false,
  resetModal: false,
  user: {},
  openSnackbar: false,
  snackbarMsg: "",
  enableOneTap: false,
  enableLogin: false,
};

const toggleSnackbar = (state, action) => {
  return updateObject(state, {
    openSnackbar: action.open,
    snackbarMsg: action.message,
  });
};

const toggleResetModal = (state, action) => {
  return updateObject(state, {
    resetModal: action.payload,
    loginModal: false,
    registerModal: false,
    alertModal: false,
  });
};

const toggleRegisterModal = (state, action) => {
  return updateObject(state, {
    otpModal: false,
    registerModal: action.payload,
    loginModal: false,
  });
};

const toggleLoginModal = (state, action) => {
  return updateObject(state, {
    otpModal: false,
    registerModal: false,
    loginModal: action.payload,
  });
};

const toggleAlertModal = (state, action) => {
  return updateObject(state, {
    alertModal: action.payload,
    registerModal: false,
    loginModal: false,
  });
};

const setUser = (state, action) => {
  return updateObject(state, {
    user: action.payload,
  });
};

const enableGoogleTap = (state) => {
  return updateObject(state, {
    enableOneTap: true,
  });
};

const enableLogin = (state) => {
  return updateObject(state, {
    enableLogin: true,
  });
};

const authReducer = (state = authState, action) => {
  switch (action.type) {
    // case "OTP_MODAL":
    //   return toggleOTPModal(state, action);
    case actionTypes.REGISTER_MODAL:
      return toggleRegisterModal(state, action);
    case actionTypes.LOGIN_MODAL:
      return toggleLoginModal(state, action);
    case actionTypes.ALERT_MODAL:
      return toggleAlertModal(state, action);
    case actionTypes.RESET_MODAL:
      return toggleResetModal(state, action);
    case actionTypes.SET_USER:
      return setUser(state, action);
    case actionTypes.TOGGLE_SNACKBAR:
      return toggleSnackbar(state, action);
    case actionTypes.ENABLE_GOOGLE_ONETAP:
      return enableGoogleTap(state);
    case actionTypes.ENABLE_LOGIN:
      return enableLogin(state);
    default:
      return state;
  }
};

const memberReducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.MEMBER_UPDATED:
      return action.member;

    default:
      return state;
  }
};

export const REDUCERS = {
  storyReducer: storyReducer,
  authReducer: authReducer,
  member: memberReducer,
};
