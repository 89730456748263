import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
// format example
// D/MMM/YYYY hh:mm a

export function formatDate(dateTime, format) {
  const newDate = dayjs(dateTime).format(format);

  return newDate;
}

export function formatDateIST(dateTime, format) {
  return dayjs(dateTime).tz("Asia/Kolkata").format(format);
}

export function getAnalyticsTimeRange(dateTime) {
  const publishTime = dayjs.tz(dateTime, "Asia/Kolkata").format("hh:mm:s A");
  const currentDate = dayjs.tz(new Date(), "Asia/Kolkata").format("YYYY-MM-DD");
  const compareDate = `${currentDate} ${publishTime}`;

  let timeRange = "";

  if (dayjs(compareDate).isBetween(`${currentDate} 00:00:00`, `${currentDate} 03:59:59`)) {
    timeRange = "00:00 - 03:59";
  } else if (dayjs(compareDate).isBetween(`${currentDate} 04:00:00`, `${currentDate} 07:59:59`)) {
    timeRange = "04:00 - 07:59";
  } else if (dayjs(compareDate).isBetween(`${currentDate} 08:00:00`, `${currentDate} 11:59:59`)) {
    timeRange = "08:00 - 11:59";
  } else if (dayjs(compareDate).isBetween(`${currentDate} 12:00:00`, `${currentDate} 15:59:59`)) {
    timeRange = "12:00 - 15:59";
  } else if (dayjs(compareDate).isBetween(`${currentDate} 16:00:00`, `${currentDate} 19:59:59`)) {
    timeRange = "16:00 - 19:59";
  } else if (dayjs(compareDate).isBetween(`${currentDate} 20:00:00`, `${currentDate} 23:59:59`)) {
    timeRange = "20:00 - 23:59";
  }

  return timeRange;
}
