import React from "react";
import "./section-molecule.m.css";
import { ChildItem } from "../child-item";
import { ParentItem } from "../parent-item";

const subsections = [
  {
    name: "Dota 2",
    slug: "dota2",
  },
  {
    name: "CS:GO",
    slug: "csgo",
  },
  {
    name: "Esports",
    slug: "esports",
  },
  {
    name: "Mobile Esports",
    slug: "mobileesports",
  },
  {
    name: "Gaming",
    slug: "gaming",
  },
  {
    name: "Blockchain Games",
    slug: "blockchaingames",
  },
  {
    name: "Premium",
    slug: "premium",
  },
];

function SectionMolecule(props) {
  const [isExpanded, showPanel] = React.useState(false);

  function togglePanel() {
    if (!isExpanded) {
      showPanel(true);
      document.getElementById(`nav-drawer-child-${props.id}`).style.maxHeight = "1000px";
    } else {
      showPanel(false);
      document.getElementById(`nav-drawer-child-${props.id}`).style.maxHeight = "0px";
    }
  }

  let premium = false;
  let gaming = false;
  if (props.label === "Premium") {
    premium = true;
  }

  if (props.label === "Gaming") {
    gaming = true;
  }

  let childDiv = subsections.map((data, index) => {
    return <ChildItem cssName={"text-1"} label={data.name} key={index} path={`/${data.slug}`} click={props.click} />;
  });

  if (!props.hasChildren) {
    return (
      <div>
        <div style={{ cursor: "pointer" }}>
          <ParentItem label={props.label} slug={props.slug} click={props.click} link={true} />
        </div>
        <div styleName="section-divider" />
      </div>
    );
  }

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={togglePanel}>
        <ParentItem label={props.label} expanded={isExpanded} />
      </div>
      <div id={`nav-drawer-child-${props.id}`} styleName="child-div">
        {childDiv}
      </div>
      <div styleName="section-divider" />
    </div>
  );
}

export { SectionMolecule };
