import React from "react";
import "./text-button.m.css";

function TextButton(props) {
  return (
    <div {...props} styleName="text">
      {props.label}
    </div>
  );
}

export { TextButton };
