import React from "react";

import { LoadingIndicator } from "@quintype/components";
import "./loading-indicator.m.css";

const LoadingIndicatorComponent = () => (
  <LoadingIndicator>
    <div styleName="loading-animation">
      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="linear-gradient" x1="68.38" x2="32" y1="74.37" y2="11.36" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#3c9848"></stop>
            <stop offset="1" stopColor="#98c93c"></stop>
          </linearGradient>
        </defs>
        <path
          d="M93.82 45.61A7.35 7.35 0 0090 39.24l-.2-.12-.23-.06-1-.31 4.62-13.47-9.76-.42L89.14 22l-19.88-6.44-.55-1.39L72.94.46l-27.83 12 1.57-8.42L17.5 26.32l.12 1.52L6 28.63l5.12 11.42-.22.06-.21.11a6.85 6.85 0 00-3.56 6c-.08 1.88-.42 10.9.21 13.71.72 3.23 2.9 4.5 4.05 4.73.05 0 4.82 1.09 7.94 1.63a7.38 7.38 0 001.29.12h.07l1.05 13.69 7.16 5.2v-2l8.78 7.7-.08-2.85 12.25 11.39 10.61-10.47-.07 3 16-10.79.51-2.28 11.51-1.61v-1.18V65.9l.9-.2c.35-.08 3.38-.82 4.32-5.06.67-3.15.25-13.64.19-15.03z"
          styleName="cls-1"
        ></path>
        <path
          d="M43.31 43.42s-6.19-1.63-9.38-2.06a4.11 4.11 0 014.73-2.27c3.19.63 4.65 4.33 4.65 4.33z"
          styleName="cls-2"
        ></path>
        <path
          d="M91.66 45.53A5.19 5.19 0 0089 41s-1.38-.41-3.09-.88v-.06l3.49-12-22.18-2.7-43.64 4.75v-.86L10.44 31l3.66 10 1 .08c-1.72.47-3.18.9-3.18.9a4.72 4.72 0 00-2.46 4.13S9 56.56 9.62 59.3s2.45 3.13 2.45 3.13 4.75 1.06 7.81 1.6 3.44-1.6 3.44-1.6a22.71 22.71 0 001.33-7l31.21 12.22 21.69-2.75a3.86 3.86 0 002.71.33l1.49-.23.37.89-.38.06v5.65L70 74.58l-.41-1.79-5.79 2.77 1.24 3L84 74.07l.77-.18.07-.9.62-7.64-1.26.18.72-1.21c2.15-.45 3.94-.85 3.94-.85s2-.42 2.68-3.44.12-14.5.12-14.5z"
          styleName="cls-3"
        ></path>
        <path
          d="M92.42 45.53a6 6 0 00-3.09-5.21H89.18l-2.39-.68 3.54-12.16L78.22 26l5.5-3.69-15.44-5.66-1-2.41 2.2-9.94-26.02 10.41.54-5.79-24.87 18 .17 2.14-9.9 1.29 3.82 10.47-1.5.44h-.16a5.45 5.45 0 00-2.85 4.79c0 .72-.45 10.59.17 13.36.71 3.18 2.94 3.68 3 3.7s4.8 1.07 7.85 1.61a7 7 0 001.07.09 4 4 0 001.27-.19l1.14 14.46 4.52 3.28V80l8.67 7.67-.08-2.94 13.6 10.41 12-9.64-.08 3.74 13.37-9 .57-2.71 8.51-2 .77-.18 1.08-.26.09-1.1.07-.9.62-7.64.06-.82 2-.44c.11 0 2.51-.56 3.27-4 .66-3.03.2-13.89.17-14.66zM11.48 31.61l7.93-1.05.67 8.55h-.25c-1.09.18-3.3.77-5.14 1.27zm-1.25 14.48a4 4 0 01.64-2.09 26.11 26.11 0 01.66 8.33c-.2 3.93-.34 4.62-1.13 7v-.14c-.58-2.62-.14-12.88-.13-13v-.06zM11 60.78a25.08 25.08 0 001.72-8.39 34.41 34.41 0 00-1-9.4c.14-.1.28-.2.45-.3.41-.12 2.3-.66 4.19-1.17a58 58 0 011.3 11.22 52.71 52.71 0 01-1.23 9.86c-2.22-.46-4.22-.91-4.25-.92a1.46 1.46 0 01-1.18-.9zm9 2.5l-2.53-.48a52.93 52.93 0 001.25-10 57.89 57.89 0 00-1.33-11.5c1.08-.28 2.08-.52 2.68-.62 1.6-.26 2.35 1.23 2.58 2.16.73 2.84 2.66 12.67 0 19.35v.13c-.1.1-.44 1.34-2.65.96zm65.33-24.11l-1.85-.5-5.85-11.26 10.74 1.31zM81.6 52.83a58.55 58.55 0 001.4 11.1c-1 .2-2 .4-2.86.55-2.48.43-2.86-1-2.89-1.17-1.7-8.25-.47-19.75-.09-21.47.21-1 1-2.44 2.49-2.44a3.29 3.29 0 01.42 0c.67.11 1.8.38 3 .7a65.37 65.37 0 00-1.47 12.73zM78.24 66a4.4 4.4 0 001 .1 6.9 6.9 0 001-.08v4.37l-3 .78zm6.55 7l-.07.9-.77.18-7.81 1.86.66-3.12 4.89-1.26v-5.65l3.67-.55zm3.85-10.26s-2.22.5-4.66 1a57.34 57.34 0 01-1.37-10.91 64.08 64.08 0 011.45-12.43c1.82.49 3.65 1 4.37 1.23a65.61 65.61 0 00.86 20.76 2.06 2.06 0 01-.65.34zm2.11-2.86a5.26 5.26 0 01-.61 1.58 64.9 64.9 0 01-.76-19.25 4.48 4.48 0 011.52 3.3v.07c0 .1.49 11.42-.15 14.29z"
          styleName="cls-4"
        ></path>
        <path
          d="M49.93 35.93l2.52.73-2.52 2 9-1.26-9 4.66v44.02l2.7-2.58-2.7 11.68 12-9.64-.08 3.74 13.37-9 .57-2.71 8.51-2 .77-.18 1.08-.26.09-1.1.07-.9.62-7.64.06-.82 2-.44c.11 0 2.51-.56 3.27-4 .68-3 .22-13.9.19-14.67a6 6 0 00-3.09-5.21H89.21l-2.39-.68 3.54-12.16L78.22 26l5.5-3.69-15.44-5.66-1-2.41 2.2-9.94-19.6 7.83v23.8zm38.44-7.21l-3 10.45-1.85-.5-5.89-11.26zM77.15 41.84c.21-1 1-2.44 2.49-2.44a3.29 3.29 0 01.42 0c.67.11 1.8.38 3 .7a65.37 65.37 0 00-1.46 12.73 58.55 58.55 0 001.4 11.1c-1 .2-2 .4-2.86.55-2.48.43-2.86-1-2.89-1.17-1.71-8.25-.48-19.75-.1-21.47zM80.21 66v4.37l-3 .78L78.24 66a4.4 4.4 0 001 .1 6.9 6.9 0 00.97-.1zm4.58 7l-.07.9-.77.18-7.81 1.86.66-3.12 4.89-1.26v-5.65l3.67-.55zm3.85-10.26s-2.22.5-4.66 1a57.34 57.34 0 01-1.37-10.91 64.08 64.08 0 011.45-12.43c1.82.49 3.65 1 4.37 1.23a65.61 65.61 0 00.86 20.76 2.06 2.06 0 01-.65.34zm2.11-2.86a5.26 5.26 0 01-.61 1.58 64.9 64.9 0 01-.76-19.25 4.48 4.48 0 011.52 3.3v.07c0 .1.49 11.42-.15 14.29z"
          styleName="cls-5"
        ></path>
        <path
          d="M69.98 74.58L69.57 72.78 63.78 75.56 65.02 78.57 83.95 74.07 84.72 73.89 84.8 72.99 85.41 65.34 81.73 65.9 81.69 71.55 69.98 74.58z"
          styleName="cls-3"
        ></path>
        <path
          d="M68.25 45.52c0-.59 2-1.45 2-1.45s-2-.32-3.66-.39a23.94 23.94 0 00-5.83.52c-4.29 1.28-12.11 5.58-12.11 5.58a99.27 99.27 0 00-19.87-2.57s1.07 1.18 1.56 1.67.62.86.17 1.42a19.37 19.37 0 00-1.66 2.47.83.83 0 00.06 1c2.91 5.71 6.95 8 10 9.78a3 3 0 00.95.33 8.22 8.22 0 013-1.41 3.58 3.58 0 011.43-.09c.65.43-.09.78-.09.78-3.22 2-3.55 4-3.55 4-.27.78.18 1 .18 1C47 70.54 56 69.46 58.8 69a.67.67 0 00.65-.63 5.55 5.55 0 00-1.16-3 24.18 24.18 0 00-1.79-2.25s.59-.91 2.24-.37a4.83 4.83 0 011.67 1.33 4 4 0 002-.38c2.73-1.47 6.66-5.88 7.82-7.55a4.19 4.19 0 00.28-1.42s-.42-2.19-.62-3.14-1.45-5-1.45-5a3.58 3.58 0 01-.19-1.07zM44.26 76c2 .65 8.81.32 10.3.18l1.57-.75a6.93 6.93 0 001.13-1.3c.74-1.2 1.54-2.78 1.81-3.13a.22.22 0 00-.07-.4 37.8 37.8 0 01-7.42 1.11h-1.37a33.33 33.33 0 01-9.73-1.23c-.11 0-.12.33-.12.33a21.51 21.51 0 002 3.38c1.22 1.59 1.81 1.72 1.9 1.81z"
          styleName="cls-3"
        ></path>
        <circle cx="58.67" cy="54.45" r="3.04" styleName="cls-5"></circle>
        <circle cx="40.31" cy="54.45" r="3.04" styleName="cls-5"></circle>
        <path
          d="M22.85 26.42L39.18 15 38.44 20.28 65.44 9.13 62.87 16.5 63.36 18.47 77.86 23.39 70.88 26.09 76.38 36.66 64.34 25.35 70.85 23.02 56.97 18.35 61.27 13.06 33.28 25.07 34.63 20.41 22.85 26.42z"
          styleName="cls-3"
        ></path>
        <path
          d="M43.43 43.33s-6.15-1.63-9.33-2.06A4.1 4.1 0 0138.81 39c3.19.65 4.62 4.33 4.62 4.33z"
          styleName="cls-5"
        ></path>
      </svg>
      Loading
    </div>
  </LoadingIndicator>
);

export default LoadingIndicatorComponent;
