import React from "react";
import "./app-logo.m.css";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { utmConsistencyHelper } from "../../helper/helper";
import { useSelector } from "react-redux";
import get from "lodash/get";
import MobileLogo from "../../../../assets/images/afk_logo_xs.svg";
import DesktopLogo from "../../../../assets/images/afk_logo_black.svg";

function AppLogo(props) {
  const currentUrl = useSelector((state) => get(state, ["qt", "currentPath"], "/"));
  return (
    <div>
      <Link href={utmConsistencyHelper(`/`, currentUrl)} callback={props.homeClick}>
        <img
          id="afk-header-logo-xs"
          styleName="afk-logo-xs"
          width="36.16"
          height="41"
          src={assetify(MobileLogo)}
          alt="Logo"
        />
        <img
          id="afk-header-logo-sm"
          width="120"
          height="64"
          styleName="afk-logo-sm"
          src={assetify(DesktopLogo)}
          alt="Logo"
        />
      </Link>
    </div>
  );
}

export { AppLogo };
