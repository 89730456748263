import React from "react";
import "./child-item.m.css";
import { Link } from "@quintype/components";
import { utmConsistencyHelper } from "../../../helper/helper";
import { useSelector } from 'react-redux';
import get from "lodash/get";

function ChildItem(props) {
  const currentUrl = useSelector((state) => get(state, ["qt", "currentPath"], "/"));
  return (
    <Link href={utmConsistencyHelper(props.path, currentUrl)} styleName={props.cssName} callback={props.click}>
      {props.label}
    </Link>
  );
}

export { ChildItem };
