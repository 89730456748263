import React from "react";
import "./snackbar.m.css";
import get from "lodash/get";
import { connect } from "react-redux";
import { CloseIcon } from "../../basic/icons/close-icon";

class Snackbar extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.openSnackbar === true) {
      this.toggleSnackbar();
    }
    return true;
  }

  toggleSnackbar = () => {
    let snackbar = document.getElementById("snackbar");
    snackbar.classList.add("snackbar-show");

    setTimeout(() => {
      this.closeSnackbar();
    }, 5000);
  };

  closeSnackbar = () => {
    snackbar.classList.remove("snackbar-show");
    this.props.closeSnackbar();
  };

  render() {
    let { snackbarMsg } = this.props;

    return (
      <div id="snackbar" styleName="root">
        <div styleName="text">{snackbarMsg || "Message"}</div>
        <div styleName="icon" onClick={this.closeSnackbar}>
          <CloseIcon />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openSnackbar: get(state, ["authReducer", "openSnackbar"], false),
    snackbarMsg: get(state, ["authReducer", "snackbarMsg"], false)
  };
};

const mapDispatchToProps = dispatch => ({
  closeSnackbar: () => {
    dispatch({
      type: "TOGGLE_SNACKBAR",
      open: false,
      message: ""
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
