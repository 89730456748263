import React from "react";
import "./icons.m.css";

export function Search({ color = "white", cssName = "search-icon" }) {
  return (
    <div styleName={cssName}>
      <svg viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.8657 21.5695L16.4749 15.1787C17.687 13.6822 18.4167 11.7797 18.4167 9.70837C18.4167 4.9066 14.5101 1 9.70833 1C4.90656 1 1 4.9066 1 9.70837C1 14.5101 4.9066 18.4167 9.70837 18.4167C11.7797 18.4167 13.6822 17.687 15.1787 16.4749L21.5695 22.8658C21.7486 23.0447 22.0387 23.0447 22.2178 22.8658L22.8658 22.2177C23.0447 22.0387 23.0447 21.7485 22.8657 21.5695ZM9.70837 16.5834C5.91727 16.5834 2.83335 13.4995 2.83335 9.70837C2.83335 5.91727 5.91727 2.83335 9.70837 2.83335C13.4995 2.83335 16.5834 5.91727 16.5834 9.70837C16.5834 13.4995 13.4995 16.5834 9.70837 16.5834Z"
          fill={color}
          stroke={color}
          strokeWidth="0.75"
        />
      </svg>
    </div>
  );
}
