import React from "react";
import "./icons.m.css";

export function KeyboardRightIcon({ cssName = "keyboard-right-icon", color = "white" }) {
  return (
    <div styleName={cssName}>
      <svg viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M7 2L17 12L7 22" stroke="#0D1319" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}
