import React from "react";
import { Search } from "../../basic/icons/search";
import { Link } from "@quintype/components";

import "./navbar-search.m.css";

const NavbarSearch = () => {
  return (
    <div style={{ display: "flex" }}>
      <Link id="header-search-btn" aria-label="Search" styleName="search__btn" href="/search">
        <Search />
      </Link>
    </div>
  );
};

export default NavbarSearch;
