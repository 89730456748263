import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import assetify from "@quintype/framework/assetify";
import MobileLogo from "../../../../assets/images/premium_circle.svg";
import { CloseIcon } from "../../basic/icons/close-icon";
import "./subscriptionpopup.m.css";

const SubscriptionPopup = ({ pageType, currentPath }) => {
  const [openModal, setOpenModal] = useState(false);
  const container = typeof window !== "undefined" && document.getElementById("portal");
  const [hasOpened, setHasOpened] = useState(false);

  function hideModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    if (hasOpened) return;
    if (pageType !== "story-page") return;
    if (currentPath.includes("/global")) return;
    const timeoutId = setTimeout(() => {
      const portal = document.getElementById("portal");
      if (!portal.hasChildNodes()) {
        setOpenModal(true);
        setHasOpened(true);
      }
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasOpened, pageType, currentPath]);

  return (
    <Dialog.Root open={openModal} onOpenChange={hideModal}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent"
          onOpenAutoFocus={(e) => e.preventDefault()}
          style={{ background: "#000", maxWidth: "320px" }}
        >
          <div className="flex-container">
            <div className="flex-mobile-12 flex-desktop-12" styleName="modal-root">
              <div onClick={hideModal} style={{ float: "right" }} styleName="close-icon">
                <CloseIcon color="#fff" style={{ height: "25px", width: "25px", cursor: "pointer" }} />
              </div>
              <img alt="AFK Gaming Premium Logo" src={assetify(MobileLogo)} styleName="afk-logo" />
              <div styleName="modal-text">
                <h3>AFK GAMING</h3>
                <h2>STOP WASTING YOUR TIME.</h2>
                <p>
                  Read <strong>real content</strong> about the industry you love and care about.
                </p>
                <p>
                  Get the best quality coverage aimed at making you one of the smartest people in gaming and esports.
                </p>
                <button
                  id="popup-subscribe"
                  styleName="green-btn"
                  onClick={() => (window.location.href = "/subscription?utm_source=archived")}
                >
                  <span>SUBSCRIBE</span>
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SubscriptionPopup;
