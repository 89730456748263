import React, { useEffect } from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { currentUser } from "@quintype/bridgekeeper-js";
import { MEMBER_UPDATED } from "../store/actionTypes";
import { createLogin, getSectionFromUrl } from "../helper/helper";

function oneTapLogin(token) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`https://${window.location.hostname}/api/auth/v1/login/google-one-tap`)
    .post({
      token: token,
      "redirect-url": window.location.href,
    })
    .res();
}

const OneTapLogin = () => {
  const member = useSelector((state) => get(state, ["member"], null));
  const enableOneTap = useSelector((state) => get(state, ["authReducer", "enableOneTap"], false));
  const dispatch = useDispatch();

  const getCurrentUser = async () => {
    try {
      const currentUserResp = await currentUser();
      const user = get(currentUserResp, ["user"], null);
      posthog.identify(user.id, {
        email: user.email,
        name: user.name,
      });
      dispatch({ type: MEMBER_UPDATED, member: user });
      return user;
    } catch (err) {
      console.log("error--------", err);
    }
  };

  const onOneTapSignedIn = (response) => {
    dataLayer.push({ event: "oneTapClick", loginType: "onetap", currentPath: window.location.href });
    oneTapLogin(response.credential)
      .then(async () => {
        dataLayer.push({ event: "oneTapLoginSuccess", loginType: "onetap", currentPath: window.location.href });
        await getCurrentUser();
        const section = getSectionFromUrl(window.location.pathname);
        const data = {
          section,
        };
        createLogin(data);
      })
      .catch((err) => {
        dataLayer.push({ event: "oneTaploginFail", loginType: "onetap", currentPath: window.location.href });
      });
  };

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: "375796380652-epon10vhfh2unsdb6dgoaofekj4mba8c.apps.googleusercontent.com",
      cancel_on_tap_outside: false,
      callback: onOneTapSignedIn,
    });

    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        console.log(notification.getNotDisplayedReason());
      } else if (notification.isSkippedMoment()) {
        console.log(notification.getSkippedReason());
      } else if (notification.isDismissedMoment()) {
        console.log(notification.getDismissedReason());
      } else if (notification.isDisplayed()) {
        dataLayer.push({ event: "oneTapView" });
      }
    });
  };

  useEffect(() => {
    const location = window.location.href;
    const showOneTap = location.includes("/global") || location.includes("/subscription");
    if (enableOneTap && !member && showOneTap) {
      setTimeout(() => {
        const el = document.createElement("script");
        el.setAttribute("src", "https://accounts.google.com/gsi/client");
        el.defer = true;
        el.onload = () => initializeGSI();
        document.querySelector("body").appendChild(el);
      }, 1000);
    }
  }, [enableOneTap]);

  return <div style={{ display: "none" }} />;
};

export default OneTapLogin;
