import React from "react";
import "./social-follow-icons.m.css";
import assetify from "@quintype/framework/assetify";
import FacebookIcon from "../../../../assets/images/icon_fb.png";
import TwitterIcon from "../../../../assets/images/icon_twitter.png";
import InstagramLogo from "../../../../assets/images/icon_insta.png";
import DiscordLogo from "../../../../assets/images/icon_discord.png";
import LinkedinLogo from "../../../../assets/images/icon_linkedin.png";
import GoogleNewsLogo from "../../../../assets/images/follow_gnews.png";

const SocialFollowIcons = () => {
  return (
    <div styleName="social-icon-container">
      <a
        href="https://www.facebook.com/AFKGamingOfficial"
        target="_blank"
        rel="noopener noreferrer"
        styleName="icon-container"
      >
        <img loading="lazy" height="30" width="30" alt="facebook-logo" src={assetify(FacebookIcon)} />
      </a>
      <a href="https://twitter.com/afkgaming" target="_blank" rel="noopener noreferrer" styleName="icon-container">
        <img loading="lazy" height="30" width="30" alt="twitter-logo" src={assetify(TwitterIcon)} />
      </a>
      <a
        href="https://www.instagram.com/afkgamingesports/"
        target="_blank"
        rel="noopener noreferrer"
        styleName="icon-container"
      >
        <img loading="lazy" height="30" width="30" alt="instagram-logo" src={assetify(InstagramLogo)} />
      </a>
      <a href="https://discord.com/invite/v6v9jMV" target="_blank" rel="noopener noreferrer" styleName="icon-container">
        <img loading="lazy" height="30" width="30" alt="discord-logo" src={assetify(DiscordLogo)} />
      </a>
      <a
        href="https://www.linkedin.com/company/afk-gaming-pvt-ltd-"
        target="_blank"
        rel="noopener noreferrer"
        styleName="icon-container"
      >
        <img loading="lazy" height="30" width="30" alt="linkedin-logo" src={assetify(LinkedinLogo)} />
      </a>
      <div style={{ flexBasis: "100%", display: "flex", justifyContent: "center" }}>
        <a
          href="https://news.google.com/publications/CAAqBwgKMPm-lAsw2ISqAw"
          target="_blank"
          rel="noopener noreferrer"
          styleName="icon-container"
        >
          <img loading="lazy" alt="google-news-logo" width="240" height="36" src={assetify(GoogleNewsLogo)} />
        </a>
      </div>
    </div>
  );
};

export default SocialFollowIcons;
