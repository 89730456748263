import dayjs from "dayjs";
import Cookies from "js-cookie";
import { formatDateIST, getAnalyticsTimeRange } from "./date-helper";

//Get string without space
export const removeSpacesAndLowerCase = (string) => {
  const newString = string.replace(/ /g, "");
  return newString.toLowerCase();
};

export const trendingCollectionHelper = (collection, title) => {
  const mobileTrending = collection.items.find((c) => c.slug === "mobile-trending").items.slice(0, 1);

  const csgoTrending = collection.items.find((c) => c.slug === "csgo-trending").items.slice(0, 1);

  const dota2Trending = collection.items.find((c) => c.slug === "dota-trending").items.slice(0, 1);

  let esportsTrending = collection.items.find((c) => c.slug === "esports-trending").items.slice(0, 1);

  let blockchainTrending = collection.items.find((c) => c.slug === "blockchain-trending").items.slice(0, 1);

  let trending = [];
  switch (title) {
    case "csgo":
      return (trending = [
        ...csgoTrending,
        ...esportsTrending,
        ...mobileTrending,
        ...dota2Trending,
        ...blockchainTrending,
      ]);
    case "dota2":
      return (trending = [
        ...dota2Trending,
        ...esportsTrending,
        ...mobileTrending,
        ...csgoTrending,
        ...blockchainTrending,
      ]);
    case "mobileesports":
      return (trending = [
        ...mobileTrending,
        ...esportsTrending,
        ...dota2Trending,
        ...csgoTrending,
        ...blockchainTrending,
      ]);
    case "blockchaingames":
      return (trending = [
        ...blockchainTrending,
        ...esportsTrending,
        ...mobileTrending,
        ...dota2Trending,
        ...csgoTrending,
      ]);
    default:
      return (trending = [
        ...esportsTrending,
        ...mobileTrending,
        ...dota2Trending,
        ...csgoTrending,
        ...blockchainTrending,
      ]);
  }
};

//Get date using dayjs
export const getLocalDate = (dateObject) => {
  return dayjs(dateObject).format("DD MMM, YYYY");
};

//Get time using dayjs
export const getLocalTime = (dateObject) => {
  return dayjs(dateObject).format("h:mm A");
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPercentage = (count, total) => {
  let percentage = (count / total) * 100;
  return Math.round(percentage);
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getLoginMethod = (verificationStatus) => {
  if (verificationStatus === "social-google") {
    return "google";
  } else if (verificationStatus === "social-facebook") {
    return "facebook";
  } else if (verificationStatus === "email") {
    return "native";
  } else {
    return verificationStatus;
  }
};

export const utmConsistencyHelper = (toUrl, currentUrl) => {
  const utm_source = getParameterByName("utm_source", currentUrl);
  const utm_medium = getParameterByName("utm_medium", currentUrl);
  let isGlance = false;
  if (utm_source === "g") {
    isGlance = true;
  }

  if (isGlance) {
    const finalUrl = `${toUrl}?utm_source=${utm_source}&utm_medium=${utm_medium}`;
    return finalUrl;
  }
  return toUrl;
};

// GTM Datalayer pageview helper

export const sendPageView = (story, index) => {
  setTimeout(() => {
    let storyViewCount = 1;

    if (Cookies.get("storyViewCount")) {
      storyViewCount = Cookies.get("storyViewCount") || 1;
      storyViewCount++;
    }

    Cookies.set("storyViewCount", storyViewCount, { expires: 5555 });

    let shelfLife = "";
    let storyAttr = story.metadata["story-attributes"];
    if (storyAttr && storyAttr.shelfLife) {
      shelfLife = storyAttr.shelfLife;
    }

    // reduce number of tags to 5 (max)
    const tags = story.tags
      .slice(0, 5)
      .map((tag) => tag.name)
      .join();

    const data = {
      storyAuthor: story.authors[0].name,
      storyType: story["story-template"],
      storySection: story.sections[0]["parent-id"] ? story.sections[0].collection.name : story.sections[0].name,
      storyPosition: parseInt(index) + 1,
      storyTitle: story.headline,
      publishDate: formatDateIST(story["published-at"], "YYYY-MM-DD"),
      publishDay: parseInt(formatDateIST(story["published-at"], "DD")),
      publishMonth: parseInt(formatDateIST(story["published-at"], "MM")),
      publishYear: parseInt(formatDateIST(story["published-at"], "YYYY")),
      publishTimeRange: getAnalyticsTimeRange(story["published-at"]),
      storyUpdatedDate: formatDateIST(story["updated-at"], "DD MMM YYYY, hh:mm A"),
      storyId: story.id,
      storyViewCount: storyViewCount,
      shelfLife: shelfLife,
      storyTags: tags.length > 0 ? tags : undefined,
      event: "storyPageView",
    };

    dataLayer.push(data);
  }, 150);
};

export const sendNonStoryPageView = () => {
  setTimeout(() => {
    dataLayer.push({
      storyAuthor: undefined,
      storyType: undefined,
      storySection: undefined,
      storyPosition: undefined,
      storyTitle: undefined,
      publishDate: undefined,
      publishDay: undefined,
      publishMonth: undefined,
      publishYear: undefined,
      publishTimeRange: undefined,
      storyUpdatedDate: undefined,
      storyId: undefined,
      storyTags: undefined,
      storyViewCount: undefined,
      scrollDepth: undefined,
      shelfLife: undefined,
      event: "nonStoryPageView",
    });
  });
};

export function getSectionFromUrl(path) {
  const section = path.split("/")[1];
  let finalSection = "";
  switch (section.toLowerCase()) {
    case "dota2":
      finalSection = "dota2";
      break;
    case "csgo":
      finalSection = "csgo";
      break;
    case "mobileesports":
      finalSection = "mobileesports";
      break;
    case "esports":
      finalSection = "esports";
      break;
    case "gaming":
      finalSection = "gaming";
      break;
    default:
      finalSection = "other";
      break;
  }
  return finalSection;
}

export function createUserinDB(user) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`https://${window.location.hostname}/users/profile`)
    .post(user)
    .res();
}

export function createLogin(section) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`https://${window.location.hostname}/user/login`)
    .post(section)
    .res();
}

async function getIntegrationToken(id) {
  const response = await fetch(`/api/auth/v1/access-token/integrations/${id}`);
  return response.headers.get("x-integration-token");
}

export async function getSubscriptionForUser(key, id) {
  const token = await getIntegrationToken(id);
  const response = await fetch(
    `https://www.accesstype.com/api/access/v1/members/me/subscriptions?key=${key}&accesstype_jwt=${token}`
  );
  if (!response.ok) throw Error(response.status);

  const subscription = await response.json();
  return subscription;
}
